import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { ApolloClient } from '@apollo/client'
import { Transition } from '@headlessui/react'
import classNames from 'classnames'
import { setCookie } from 'cookies-next'
import { useRouter } from 'next/router'
import { When } from 'react-if'
import { LinkButton } from '@/atoms/Button'
import { PayItForwardIcon } from '@/atoms/Icons/PayItForwardIcon'
import { Image } from '@/atoms/Image'
import { ResponsiveBackgroundImage } from '@/atoms/ResponsiveBackgroundImage'
import { CaptionSM, HeadingXS, ParagraphSM } from '@/atoms/Text'
import { LOGIN_STARTED_ORIGIN } from '@/constants/cookies'
import { paths } from '@/constants/paths'
import { useLanding } from '@/contexts/LandingContext'
import { ExperimentVariation, GBFadeInExperiment } from '@/experimentation'
import { FloatingCallToAction } from '@/molecules/FloatingCallToAction'
import { getCloudinaryImageUrl } from '@/utils/Cloudinary'
import { useLocale } from '@/utils/LocaleUtil'
import { useSafeAnalytics } from '@/utils/analytics'
import { useLoginUrl } from '@/utils/auth-utils'
import { useTranslate } from '@/utils/translate/translate-client'
import { GuildEmailInput } from '@/views/GuildJoinView/GuildEmailInput'

const buttonClassNames = 'justify-center min-w-[121px] px-3 py-[11px] w-full sm:w-auto'

const textShadowStyle = {
  textShadow: '0px 1px 0px rgba(0,0,0,0.3)',
}

interface MainHeroSectionProps {
  client: ApolloClient<object>
  isLoggedIn: boolean
  loading: boolean
  preview: boolean
  region: string
  isGuildMember: boolean
}

export const MainHeroSection: React.FC<MainHeroSectionProps> = ({
  client,
  loading,
  preview,
  region,
  isLoggedIn,
  isGuildMember,
}) => {
  const { t } = useTranslate('home')
  const { locale } = useLocale()
  const router = useRouter()
  const { track } = useSafeAnalytics()
  const { trackLoginStarted } = useLoginUrl()
  const { guildHomeHero } = useLanding()
  const [currentSlide, setCurrentSlide] = useState(0)
  const guildHref = guildHomeHero?.homeSlidesCollection?.items[currentSlide]?.guildHref
  const href = guildHomeHero?.homeSlidesCollection?.items[currentSlide]?.href

  const heroImgPaths = {
    mobile: 'https://images.angelstudios.com/image/upload/v1712181966/angel-studios/home/Hero_Image-mobile.png',
    tablet: 'https://images.angelstudios.com/image/upload/v1712181952/angel-studios/home/Hero_Image-tablet.png',
    desktop: 'https://images.angelstudios.com/image/upload/v1712181886/angel-studios/home/Hero_Image.png',
  }

  const handleLoginClicked = useCallback(() => {
    track('Home Sign Up Clicked')
    trackLoginStarted('home-hero')
    setCookie(LOGIN_STARTED_ORIGIN, 'home')
  }, [track, trackLoginStarted])

  const handleStatsBoxClick = useCallback(() => {
    track('Home Stats Box Clicked')
    isGuildMember && guildHref ? router.push(guildHref) : href && router.push(href)
  }, [guildHref, href, isGuildMember, router, track])

  useEffect(() => {
    const interval = setInterval(() => {
      if (currentSlide === (guildHomeHero?.homeSlidesCollection?.items?.length ?? 0) - 1) {
        setCurrentSlide(0)
      } else {
        setCurrentSlide(currentSlide + 1)
      }
    }, 6500)
    return () => clearInterval(interval)
  }, [currentSlide, guildHomeHero?.homeSlidesCollection?.items?.length])

  return (
    <main className="relative h-screen min-h-[550px] w-full">
      {!loading && (
        <FloatingCallToAction
          client={client}
          locale={locale}
          preview={preview}
          region={region}
          isGuildMember={isGuildMember}
        />
      )}
      {guildHomeHero?.homeSlidesCollection?.items[currentSlide]?.desktopImageUrl &&
      guildHomeHero?.homeSlidesCollection?.items[currentSlide]?.tabletImageUrl &&
      guildHomeHero?.homeSlidesCollection?.items[currentSlide]?.mobileImageUrl ? (
        <Fragment key={currentSlide}>
          <Transition
            show
            appear
            enter="transition-opacity duration-1000"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-5500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <ResponsiveBackgroundImage
              className="h-screen bg-cover bg-top md:bg-right-top"
              preBackgroundStyles="linear-gradient(180deg, rgba(21, 21, 21, 0.3) 50%, #151515 100%), "
              src={guildHomeHero?.homeSlidesCollection?.items[currentSlide]?.desktopImageUrl ?? ''}
              srcUrls={{
                xs: guildHomeHero?.homeSlidesCollection?.items[currentSlide]?.mobileImageUrl ?? '',
                sm: guildHomeHero?.homeSlidesCollection?.items[currentSlide]?.mobileImageUrl ?? '',
                md: guildHomeHero?.homeSlidesCollection?.items[currentSlide]?.tabletImageUrl ?? '',
                lg: guildHomeHero?.homeSlidesCollection?.items[currentSlide]?.desktopImageUrl ?? '',
                xl: guildHomeHero?.homeSlidesCollection?.items[currentSlide]?.desktopImageUrl ?? '',
                '2xl': guildHomeHero?.homeSlidesCollection?.items[currentSlide]?.desktopImageUrl ?? '',
              }}
            />
          </Transition>
        </Fragment>
      ) : (
        <ResponsiveBackgroundImage
          className="h-screen"
          src="v1706652754/angel-studios/landing/show-wall-fade-240130-btm-left.webp"
          srcUrls={{
            xs: heroImgPaths.mobile,
            sm: heroImgPaths.mobile,
            md: heroImgPaths.tablet,
            lg: heroImgPaths.desktop,
            xl: heroImgPaths.desktop,
            '2xl': heroImgPaths.desktop,
          }}
        />
      )}
      {guildHomeHero && (
        <div
          className={classNames(
            'relative h-full flex w-full flex-col items-center md:items-start justify-center md:justify-between text-center md:text-left mt-20 se:mt-4 md:mt-0',
          )}
        >
          <div></div>
          <header className="mx-6 flex max-w-[342px] flex-col gap-4 md:max-w-[520px] md:pl-6 lg:mt-16 lg:min-w-[700px] lg:max-w-full lg:pl-10">
            <HeadingXS
              className="md:photon-heading-sm lg:photon-heading-md xl:photon-heading-xl 2xl:display se:photon-title-lg md:max-w-[440px] lg:max-w-[500px]"
              weight="bold"
              style={textShadowStyle}
            >
              {guildHomeHero?.title}
            </HeadingXS>

            <ParagraphSM
              weight="medium"
              color="core-gray-200"
              className="md:photon-paragraph-md lg:photon-paragraph-xl se:photon-paragraph-xs"
            >
              {guildHomeHero?.description} <span className="font-bold lg:block">{guildHomeHero?.subtitle}</span>
            </ParagraphSM>
            <When condition={isLoggedIn}>
              <HeroButtons
                isLoggedIn={isLoggedIn}
                handleLoginClicked={handleLoginClicked}
                buttonClassNames={buttonClassNames}
                signupButtonText={t('getStarted', 'Get Started')}
              />
            </When>
            <When condition={!isLoggedIn}>
              <GBFadeInExperiment experimentName="homepage_learnmore_cta" defaultValue={false}>
                <ExperimentVariation variation={false}>
                  <div className="w-full">
                    <GuildEmailInput
                      variant="left-white"
                      loginOrigin="home"
                      hasUser={false}
                      isCentered={false}
                      experimentVariation={false}
                    />
                  </div>
                </ExperimentVariation>
                <ExperimentVariation variation={true}>
                  <div className="w-full">
                    <GuildEmailInput
                      variant="left-white"
                      loginOrigin="home"
                      hasUser={false}
                      isCentered={false}
                      experimentVariation={true}
                    />
                  </div>
                </ExperimentVariation>
              </GBFadeInExperiment>
            </When>
          </header>
          <section className="flex w-full flex-col items-center justify-center gap-6 md:flex-row-reverse md:justify-between md:gap-0 md:px-8 md:pb-8 lg:px-16 lg:pb-16">
            <div key={currentSlide}>
              <Transition
                show
                appear
                className={''}
                enter="transition-opacity duration-1000"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity duration-5500"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div
                  className={classNames(
                    'flex min-h-[80px] w-[200px] flex-col items-center justify-start gap-2 rounded-2xl bg-core-gray-950/70 p-4 se:p-2 se:min-h-[66px] lg:min-h-[86px]',
                    {
                      'cursor-pointer': href || (isGuildMember && guildHref),
                    },
                  )}
                  onClick={handleStatsBoxClick}
                >
                  {guildHomeHero?.homeSlidesCollection?.items[currentSlide]?.logoImageUrl && (
                    <div className="flex max-h-6 max-w-full items-center justify-center lg:max-h-[30px] lg:min-h-[30px]">
                      <Image
                        alt={guildHomeHero?.homeSlidesCollection?.items[currentSlide]?.statsDescription ?? ''}
                        height={24}
                        width={150}
                        className="mx-auto max-h-6 w-auto max-w-[150px] lg:max-h-[30px]"
                        src={getCloudinaryImageUrl({
                          path: guildHomeHero.homeSlidesCollection.items[currentSlide]?.logoImageUrl as string,
                          transforms: 'e_trim',
                        })}
                      />
                    </div>
                  )}
                  <CaptionSM className="text-pretty" color="core-gray-300">
                    <When condition={isGuildMember}>
                      {guildHomeHero?.homeSlidesCollection?.items[currentSlide]?.guildStatsDescription ??
                        guildHomeHero?.homeSlidesCollection?.items[currentSlide]?.statsDescription}
                    </When>
                    <When condition={!isGuildMember}>
                      {guildHomeHero?.homeSlidesCollection?.items[currentSlide]?.statsDescription}
                    </When>
                  </CaptionSM>
                </div>
              </Transition>
            </div>
            <ul className="flex h-full min-w-[98px] flex-row gap-1 md:items-end md:pb-2">
              {guildHomeHero?.homeSlidesCollection?.items?.map((item, index) => (
                <li
                  key={`${index}${item}`}
                  onClick={() => setCurrentSlide(index)}
                  className="flex cursor-pointer items-center justify-center p-1"
                >
                  <Transition
                    show={currentSlide === index}
                    className="max-h-[10px] overflow-hidden rounded-full"
                    enter="transition ease-in-out duration-850"
                    enterFrom="-translate-x-4 scale-x-0 w-2.5 bg-gray-500"
                    enterTo="translate-x-0 scale-x-1 w-8 bg-white"
                  >
                    <div
                      className={classNames('bg-white max-h-2.5 h-2.5 w-2.5 rounded-full ', {
                        '!w-8': currentSlide === index,
                      })}
                      key={`${item}${index}`}
                    />
                  </Transition>
                  {currentSlide !== index && (
                    <div
                      className={classNames('h-2.5 w-2.5 rounded-full bg-gray-500', {
                        'bg-white !w-8': currentSlide === index,
                      })}
                      key={`${item}${index}`}
                    />
                  )}
                </li>
              ))}
            </ul>
            <div className="md:min-w-[200px]"></div>
          </section>
        </div>
      )}
    </main>
  )
}

const HeroButtons = ({
  isLoggedIn,
  handleLoginClicked,
  buttonClassNames,
  signupButtonText,
}: {
  isLoggedIn: boolean
  handleLoginClicked: () => void
  buttonClassNames: string
  signupButtonText: string
}) => {
  const { t } = useTranslate('home')
  const { track } = useSafeAnalytics()

  return (
    <div className="mx-0 mb-12 mt-6 flex flex-row items-center justify-center gap-4 md:mx-0 md:flex-row md:justify-start se:mb-4">
      {isLoggedIn ? (
        <LinkButton
          href={paths.payItForward.index}
          variant="white"
          className={classNames('min-w-[175px] se:!max-h-[38px] se:!text-xs se:!leading-3 se:!mb-2', buttonClassNames)}
          onClick={() => track('Home Pay It Forward Clicked')}
        >
          <div className="relative flex w-full flex-row items-center justify-center gap-2">
            <PayItForwardIcon color="none" className="fill-inherit" />
            {t('payItForwardv2', 'Pay it Forward')}
          </div>
        </LinkButton>
      ) : (
        <LinkButton
          href={paths.signup.welcome}
          variant="white"
          className={classNames('se:!text-xs se:!leading-3 se:!max-h-[38px] se:!mb-2', buttonClassNames)}
          onClick={handleLoginClicked}
        >
          {signupButtonText}
        </LinkButton>
      )}
      <LinkButton
        href={paths.guild.join}
        variant="white"
        outline
        className={classNames(
          buttonClassNames,
          'se:!text-xs se:!leading-3 se:!max-h-[38px] border-0 hover:!bg-transparent se:!mb-2',
        )}
        onClick={() => track('Home Watch Clicked')}
      >
        {t('learnMore', 'Learn More')}
      </LinkButton>
    </div>
  )
}
